// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-about-us-js": () => import("./../../../src/pages/about-us.js" /* webpackChunkName: "component---src-pages-about-us-js" */),
  "component---src-pages-accessories-js": () => import("./../../../src/pages/accessories.js" /* webpackChunkName: "component---src-pages-accessories-js" */),
  "component---src-pages-apparel-js": () => import("./../../../src/pages/apparel.js" /* webpackChunkName: "component---src-pages-apparel-js" */),
  "component---src-pages-bags-js": () => import("./../../../src/pages/bags.js" /* webpackChunkName: "component---src-pages-bags-js" */),
  "component---src-pages-contact-us-js": () => import("./../../../src/pages/contact-us.js" /* webpackChunkName: "component---src-pages-contact-us-js" */),
  "component---src-pages-drinkware-js": () => import("./../../../src/pages/drinkware.js" /* webpackChunkName: "component---src-pages-drinkware-js" */),
  "component---src-pages-fitness-wellbeing-js": () => import("./../../../src/pages/fitness-wellbeing.js" /* webpackChunkName: "component---src-pages-fitness-wellbeing-js" */),
  "component---src-pages-gadgets-js": () => import("./../../../src/pages/gadgets.js" /* webpackChunkName: "component---src-pages-gadgets-js" */),
  "component---src-pages-home-utilities-js": () => import("./../../../src/pages/home-utilities.js" /* webpackChunkName: "component---src-pages-home-utilities-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-office-utility-js": () => import("./../../../src/pages/office-utility.js" /* webpackChunkName: "component---src-pages-office-utility-js" */),
  "component---src-pages-products-js": () => import("./../../../src/pages/products.js" /* webpackChunkName: "component---src-pages-products-js" */)
}

